<template>
  <v-dialog
    v-model="dialog.display"
    max-width="500"
    :overlay-opacity="'0.5'"
    :overlay-color="$vuetify.theme.themes.light.third"
  >
    <v-card dark>
      <v-card-title>
        <div class="text--h6">{{ $t("users.detail.title") }}</div>
        <v-spacer></v-spacer>
        <v-btn icon text>
          <v-icon @click="dialog.display = false">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-4">
        <div v-if="user.id">
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("commons.created.title")
                }}</span>
              </div>
              <div class="ml-auto">
                {{ $moment(user.created).format("YYYY-MM-DD HH:mm:ss") }}
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="subtitle-1 mb-2" v-if="user.profile.updateAt">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("commons.updated.title")
                }}</span>
              </div>
              <div class="ml-auto">
                {{ $moment(user.profile.updateAt).format("YYYY-MM-DD HH:mm:ss") }}
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("users.fields.username.title")
                }}</span>
              </div>
              <div class="ml-auto">
                {{ user.username }}
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("users.fields.role.title")
                }}</span>
              </div>
              <div class="ml-auto">
                {{ $t("users.fields.role." + user.role) }}
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("users.fields.status.title")
                }}</span>
              </div>
              <div class="ml-auto">
                <v-chip
                  class="table-chip"
                  v-if="user.isActive"
                  color="success"
                  >{{ $t("users.fields.status.enabled") }}</v-chip
                >
                <v-chip class="table-chip" v-else color="danger">{{
                  $t("users.fields.status.enabled")
                }}</v-chip>
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("users.fields.firstName.title")
                }}</span>
              </div>
              <div class="ml-auto">
                {{ user.profile.firstName }}
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("users.fields.lastName.title")
                }}</span>
              </div>
              <div class="ml-auto">
                {{ user.profile.lastName }}
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("users.fields.phone.title")
                }}</span>
              </div>
              <div class="ml-auto">
                {{ user.profile.phone }}
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("users.fields.email.title")
                }}</span>
              </div>
              <div class="ml-auto">
                {{ user.profile.email }}
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          <div
            v-if="user.depots && user.depots.length > 0"
            class="subtitle-1 mb-2"
          >
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("users.fields.wholesale.title")
                }}</span>
              </div>
              <div class="ml-auto">
                <v-chip
                  class="table-chip"
                  v-for="(wholesale, k) in user.depots"
                  :key="k"
                  color="third"
                  dark
                >
                  <span class="black--text font-weight-bold">{{
                    wholesale
                  }}</span>
                </v-chip>
              </div>
            </div>
            <v-divider></v-divider>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Object,
      default: function () {
        return {
          display: false,
        };
      },
    },
    user: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data: () => ({}),
};
</script>