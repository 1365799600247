<template>
  <div>
    <div class="mb-4">
      <div class="text-h6 font-weight-medium third--text">
        {{ $t("users.title") }}
      </div>
    </div>
    <v-card class="rounded-md mb-4" dark color="fifth">
      <div class="d-flex align-center justify-start px-4 py-3 flex-wrap">
        <v-btn
          depressed
          @click="refreshItems()"
          :loading="loading.refresh"
          class="mr-2"
        >
          <v-icon left>mdi-refresh</v-icon>
          {{ $t("btn.refresh") }}
        </v-btn>
        <v-btn depressed @click="getFilters()" :loading="loading.filter">
          <v-icon left>mdi-filter-variant</v-icon>
          {{ $t("btn.filter") }}
        </v-btn>
        <div
          :class="{
            'mr-auto': $vuetify.breakpoint.xsOnly,
            'ml-auto': $vuetify.breakpoint.smAndUp,
          }"
          class="mb-2"
        >
          <v-btn color="primary" :to="$i18n.getRouteLink('user-add')">
            <v-icon left>mdi-plus-circle-outline</v-icon>
            <span>{{ $t("btn.add") }}</span>
          </v-btn>
        </div>
      </div>
    </v-card>
    <v-card dark color="fifth">
      <v-card-title>
        <div class="text-subtitle-1">
          <span class="third--text">{{ $t("users.list.title") }}</span>
        </div>
        <v-spacer></v-spacer>
        <v-btn outlined color="third" v-if="meta.total">{{
          $tc("counter.user", meta.total, {
            count: $utils.pad(meta.total, 2),
          })
        }}</v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        :loading="loading.list"
        :headers="headers"
        :items="items"
        :options.sync="pagination"
        :footer-props="footerProps"
        :items-per-page="itemPerPage"
        :server-items-length="meta.total"
        sort-by="id"
        sort-desc
        dark
      >
        <template v-slot:body="{ items }" v-if="!_.isEmpty(items)">
          <tbody>
            <template v-for="item in items">
              <v-hover :key="item.id" v-slot="{ hover }">
                <tr>
                  <td style="min-width: 160px">
                    <span>{{
                      $moment(item.created).format("YYYY-MM-DD HH:mm:ss")
                    }}</span>
                  </td>
                  <td style="min-width: 150px">{{ item.username }}</td>
                  <td>
                    <v-chip
                      class="table-chip"
                      v-if="item.isActive"
                      color="success"
                      >{{ $t("users.fields.status.enabled") }}</v-chip
                    >
                    <v-chip class="table-chip" v-else color="danger">{{
                      $t("users.fields.status.enabled")
                    }}</v-chip>
                  </td>
                  <td>
                    <v-chip class="table-chip" color="third">
                      <span class="black--text">{{
                        $t("users.fields.role." + item.role)
                      }}</span>
                    </v-chip>
                  </td>
                  <td class="text-right">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="getDetail(item)"
                          v-show="hover || $vuetify.breakpoint.mdAndDown"
                          x-small
                          icon
                          v-bind="attrs"
                          v-on="on"
                          class="mr-2"
                          color="third"
                        >
                          <v-icon color="third">mdi-eye-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("users.list.actions.detail") }}</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :to="{ name: 'user-edit', params: { id: item.id } }"
                          v-show="hover || $vuetify.breakpoint.mdAndDown"
                          x-small
                          icon
                          v-bind="attrs"
                          v-on="on"
                          class="mr-2"
                          color="third"
                        >
                          <v-icon color="third">mdi-pencil-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("users.list.actions.edit") }}</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="deleteUser(item)"
                          v-show="hover || $vuetify.breakpoint.mdAndDown"
                          x-small
                          icon
                          v-bind="attrs"
                          v-on="on"
                          class="mr-2"
                          color="third"
                        >
                          <v-icon color="third">mdi-delete-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("users.list.actions.delete") }}</span>
                    </v-tooltip>
                  </td>
                </tr>
              </v-hover>
            </template>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
    <user-confirm-delete :dialog="dialog.delete"></user-confirm-delete>
    <user-filter :dialog="dialog.filter" @applyFilter="filterItems"></user-filter>
    <user-detail :dialog="dialog.detail" :user="user"></user-detail>
  </div>
</template>

<script>
import ListMixin from "./../../mixins/commons/list";
import UserConfirmDelete from "./../commons/confirm";
import UserFilter from "./filter";
import UserDetail from './detail';

export default {
  mixins: [ListMixin],
  data: () => ({
    path: "/api/v1/users",
    dialog: {
      filter: {
        display: false,
      },
      delete: {
        display: false,
        message: null,
      },
      detail: {
        display: false
      }
    },
    user: {}
  }),
  methods: {
    setHeaders() {
      this.headers = [
        {
          text: this.$t("commons.created.title"),
          align: "start",
          sortable: true,
          value: "created",
          width: 160,
        },
        {
          text: this.$t("users.fields.username.title"),
          align: "start",
          sortable: true,
          value: "username",
          width: 150,
        },
        {
          text: this.$t("users.fields.status.title"),
          align: "start",
          sortable: true,
          value: "isActive",
          width: 100,
        },
        {
          text: this.$t("users.fields.role.title"),
          align: "start",
          sortable: true,
          value: "role",
          width: 120,
        },
        {
          text: "",
          align: "start",
          sortable: false,
          value: null,
        },
      ];
    },
    getFilters() {
      this.dialog.filter.display = true;
    },
    getDetail(user) {
      this.user = user;
      this.dialog.detail.display = true;
    },
    deleteUser(user) {
      this.user = user;
      this.dialog.delete.message = this.$t("users.delete.confirm.title");
      this.dialog.delete.display = true;
    },
    async confirmDeleteReseller() {
      if (!this.user.id) return;
      try {
        await this.request({
          url: `api/v1/users/${this.user.id}`,
          method: "DELETE",
          messages: {
            204: this.$t("users.delete.success"),
            500: true,
          },
        });
        this.getItems();
      } catch (error) {
        // empty
      }
    },
  },
  components: {
    UserConfirmDelete,
    UserFilter,
    UserDetail
  },
};
</script>