<template>
  <div>
    <user></user>
  </div>
</template>

<script>
import User from "./../../components/users/list";
export default {
  data: () => ({}),
  metaInfo() {
    return {
      title: this.$t("users.title")
    };
  },
  components: {
    User
  }
};
</script>