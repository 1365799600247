<template>
  <v-dialog
    v-model="dialog.display"
    max-width="500"
    :overlay-opacity="'0.5'"
    :overlay-color="$vuetify.theme.themes.light.third"
  >
    <v-card dark>
      <v-card-title>
        <div class="text--h6">{{ $t("users.filter.title") }}</div>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-4">
        <v-text-field
          :label="$t('users.fields.username.title')"
          v-model="filters.username.value"
          clearable
        ></v-text-field>
        <v-select
          :label="$t('users.fields.role.description')"
          :items="rolesItems"
          v-model="filters.role.value"
          :item-text="(val) => $t(val.text)"
          clearable
        ></v-select>
        <v-select
          :label="$t('users.fields.status.description2')"
          :items="statusesItems"
          v-model="filters.isActive.value"
          :item-text="(val) => $t(val.text)"
          clearable
        ></v-select>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="resetFilter()" color="third" text>{{
          $t("btn.reset")
        }}</v-btn>
        <v-btn @click="applyFilter()" color="secondary" text>{{
          $t("btn.filter")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>



<script>
const defaultFilters = {
  username: {
    value: null,
  },
  role: {
    value: null,
  },
  isActive: {
    value: null
  }
};
import Vue from "vue";
import { mapGetters } from "vuex";

export default {
  props: {
    dialog: {
      type: Object,
      defauult: function () {
        return {
          display: false,
        };
      },
    },
  },
  data: () => ({
    filters: Vue._.cloneDeep(defaultFilters),
  }),
  methods: {
    applyFilter() {
      this.$emit("applyFilter", this.filters);
      this.dialog.display = false;
    },
    resetFilter() {
      this.filters = Vue._.cloneDeep(defaultFilters);
      this.$emit("applyFilter", {});
      this.dialog.display = false;
    },
  },
  computed: {
    ...mapGetters({ rolesItems: "roles", statusesItems: "statuses" }),
  },
};
</script>